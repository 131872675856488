export enum APIHooksKeysEnum {
  GET_USER = 'getUser',
  GET_CARD = 'getCard',
  GET_USER_BACKORDERS = 'getUserBackorders',
  GET_USER_DOMAINS = 'getUserDomains',
  GET_WHOIS = 'getWHOIS',
  GET_ALL_BACKORDERS = 'getAllBackorders',
  GET_AUCTION = 'getAuction',
  GET_AUCTION_BIDS = 'getAuctionBids',
  GET_ALL_AUCTIONS = 'getAllAuctions',
  GET_USER_AUCTIONS_WATCHLIST = 'getUserAuctionsWatchlist',
  GET_BLOG_POSTS = 'getBlogPosts',
  GET_BLOG_POST = 'getBlogPost',
  GET_BLOG_CATEGORIES = 'getBlogCategories',
  GET_BRAND_MONITOR = 'getBrandMonitor',
  GET_SEO_METRICS_LIST = 'getSEOMetricsList',
  GET_SEO_METRICS_CHANGES = 'getSEOMetricsChanges',
  GET_SEO_METRICS_HISTORY = 'getSEOMetricsChanges',
  GET_BRAND_MONITOR_WATCHLIST = 'getBrandMonitorWatchlist',
  GET_DOMAIN_SALES_LOOKUP_WATCHLIST = 'getDomainSalesLookupWatchlist',
  GET_DOMAIN_SALES = 'getDomainSales',
  GET_TASK_PROGRESS = 'getTaskProgress',
  GET_FRESH_DROPS = 'getFreshDrops',
  GET_CART = 'getCart',
  GET_WISHLIST = 'getWishlist',
  GET_DOMAIN_MONITOR = 'getDomainMonitor',
  GET_DOMAIN_CHANGELOG = 'getDomainChangelog',
  GET_ALL_REGISTERED_DOMAINS = 'getAllRegisteredDomains',
  POST_REFERRAL_EMAIL = 'postReferralEmail',
  TRACK_AFFILIATE_CLICK = 'trackAffiliateClick',
  GET_MY_REFERRALS = 'getMyReferrals',
  POST_EDIT_PAYMENT_EMAIL = 'postEditPaymentEmail',
  GET_REQUEST_PAYOUT = 'getRequestPayout',
  GET_ALL_TRENDS = 'getAllTrends',
  GET_KEYWORD_TREND = 'getKeywordTrend',
  GET_DOMAIN_SEO_ANALYSIS = 'getDomainSeoAnalysis',
}
